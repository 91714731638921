import React, { useEffect } /*, { Component }*/ from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route, /* Redirect, useLocation*/ } from 'react-router-dom'
// account
import ConfirmPasswordResetPage from './components/account/confirm-password-reset'
import CreateAcountPage from './components/account/create-account'
import LoginPage from './components/account/login'
import ResetPasswordPage from './components/account/reset-password'
import VerifyPage from './components/account/verify'
import ThankYouPage from './components/thank-you/thank-you'
import Dashboard from './components/dashboard/dashboard'
import ReferralProgram from './components/referral-program/referral-program'
// profile
import Profile from './components/profile/profile'
import EditProfile from './components/profile/edit-profile'
// data-room
import ArchiveRoom from './components/data-room/archive'
// data-contribution aka profile-management
import DataContribution from './components/data-contribution/data-contribution'
import ArchiveDataContribution from './components/data-contribution/archive-data-contribution'
import AddDataContribution from './components/data-contribution/add-data-contribution'
import EditDataContribution from './components/data-contribution/edit-data-contribution'
import ViewDataContribution from './components/data-contribution/view-data-contribution'
// data
import ShutteredCompanies from './components/data/shutdown-data/shuttered-companies'
import InvestorsLosses from './components/data/shutdown-data/investors-losses'
import DiscontinuedProducts from './components/data/shutdown-data/discontinued-products'
import ActiveCompanies from './components/data/business-data/active-companies'
import ProductsShowcase from './components/data/business-data/products-showcase'
import DataRoomPitchDecks from './components/data/data-room/pitch-decks'
import Company from './components/company/company'
import Investor from './components/investor/investor'
// startup-intelligence
import PostmortemAnalysis from './components/startup-intelligence/postmortem-analysis'
// venture-intelligence
import PortfolioAnalysis from './components/venture-intelligence/portfolio-analysis'
// competitive-intelligence
import CompetitiveLandscapeLandingPage from './components/competitive-intelligence/competitive-landscape/competitive-landscape-landing-page'
import ArchiveCompetitiveLandscape from './components/competitive-intelligence/competitive-landscape/archive-competitive-analysis'
import CreateCompetitiveLandscape from './components/competitive-intelligence/competitive-landscape/create-competitive-landscape'
import EditCompetitiveLandscape from './components/competitive-intelligence/competitive-landscape/edit-competitive-landscape'
import ViewCompetitiveLandscape from './components/competitive-intelligence/competitive-landscape/view-competitive-landscape'
// idea-validation
import IdeaValidationLandingPage from './components/tools/founders/idea-validation/idea-validation-landing-page'
import ArchiveIdeaValidation from './components/tools/founders/idea-validation/archive-idea-validation'
import CreateIdeaValidation from './components/tools/founders/idea-validation/create-idea-validation'
import EditIdeaValidation from './components/tools/founders/idea-validation/edit-idea-validation'
import ViewIdeaValidation from './components/tools/founders/idea-validation/view-idea-validation'
// pitch-deck-analysis aka business-plan-builder
import PitchDeckAnalysis from './components/tools/founders/pitch-deck-analysis/pitch-deck-analysis'
import ArchivePitchDeckAnalysis from './components/tools/founders/pitch-deck-analysis/archive-pitch-deck-analysis'
import CreatePitchDeckAnalysis from './components/tools/founders/pitch-deck-analysis/create-pitch-deck-analysis'
import EditPitchDeckAnalysis from './components/tools/founders/pitch-deck-analysis/edit-pitch-deck-analysis'
import ViewPitchDeckAnalysis from './components/tools/founders/pitch-deck-analysis/view-pitch-deck-analysis'
// no-code-builder aka no-code-web-builder
import NoCodeBuilder from './components/tools/founders/no-code-builder/no-code-builder'
import ArchiveNoCodeBuilder from './components/tools/founders/no-code-builder/archive-no-code-builder'
import CreateNoCodeBuilder from './components/tools/founders/no-code-builder/create-no-code-builder'
import EditNoCodeBuilder from './components/tools/founders/no-code-builder/edit-no-code-builder'
// simulation
import PreRevenueValuation from './components/simulation/founders/pre-revenue-valuation/pre-revenue-valuation'
import MarketSizing from './components/simulation/founders/market-sizing/market-sizing'
import FundingBenchmark from './components/simulation/founders/funding-benchmark/funding-benchmark'
// sourcing
import VeryEarlyStage from './components/sourcing/funding/very-early-stage'
import EarlyStage from './components/sourcing/funding/early-stage'
import GrowthStage from './components/sourcing/funding/growth-stage'
import LateStage from './components/sourcing/funding/late-stage'
import PostIpoStage from './components/sourcing/funding/post-ipo-stage'
import VeryEarlyStageDeals from './components/sourcing/deals/very-early-stage'
import EarlyStageDeals from './components/sourcing/deals/early-stage'
// user-management
import UsersList from './components/admins/users'
import { useRealmApp, RealmAppProvider } from './RealmApp'
// import RealmApolloProvider from './graphql/RealmApolloProvider'
// import TagManager from 'react-gtm-module';


// Your Realm app
export const APP_ID = 'flipidea-3-pputk';

const RequireLoggedInUser = ({ children }) => {
  const app = useRealmApp()

  // If no realm app user detected, ask the user to log in
  return app.currentUser ? children : <LoginPage />
}

function App() {

  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'AW-11563377743'
  //   };
  //   TagManager.initialize(tagManagerArgs);
  // }, []);

  // document.addEventListener('contextmenu', (e) => {
  //   e.preventDefault();
  // });



  return (
    <RealmAppProvider appId={APP_ID}>
      {/* <RealmApolloProvider> */}
      <Router>
        <Switch>
          {/* account */}
          <Route path='/confirm-password-reset' component={ConfirmPasswordResetPage} />
          <Route path='/create-account/:slug' component={CreateAcountPage} />
          <Route exact path='/create-account' component={CreateAcountPage} />
          <Route path='/login' component={LoginPage} />
          <Route path='/reset-password' component={ResetPasswordPage} />
          <Route path='/verify' component={VerifyPage} />
          <Route path='/thank-you' component={ThankYouPage} />

          <RequireLoggedInUser>
            <Route exact path='/' component={Dashboard} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/referral-program' component={ReferralProgram} />

            {/* profile */}
            <Route exact path='/profile' component={Profile} />
            <Route path='/edit-profile' component={EditProfile} />

            {/* data-room */}
            <Route path='/data-room/archive' component={ArchiveRoom} />

            {/* data-contribution */}
            <Route exact path='/profile-management' component={DataContribution} />
            <Route path='/profile/archive' component={ArchiveDataContribution} />
            <Route path='/profile/add' component={AddDataContribution} />
            <Route path='/profile/edit/:dataContributionId' component={EditDataContribution} />
            <Route path='/profile/view/:dataContributionId' component={ViewDataContribution} />

            {/* data */}
            <Route path='/data/shuttered-companies' component={ShutteredCompanies} />
            <Route path='/data/investors-losses' component={InvestorsLosses} />
            <Route path='/data/discontinued-products' component={DiscontinuedProducts} />
            <Route path='/data/companies' component={ActiveCompanies} />
            <Route path='/data/products-showcase' component={ProductsShowcase} />
            <Route path='/data/data-room' component={DataRoomPitchDecks} />
            <Route path='/company/:slug' component={Company} />
            <Route path='/investor/:slug' component={Investor} />

            {/* startup-intelligence */}
            <Route path='/startup-intel/postmortem-analysis' component={PostmortemAnalysis} />

            {/* venture-intelligence */}
            <Route path='/venture-intel/portfolio-analysis' component={PortfolioAnalysis} />

            {/* competitive-intelligence */}
            <Route exact path='/competitive-intel/competitive-landscape' component={CompetitiveLandscapeLandingPage} />
            <Route path='/competitive-intel/competitive-landscape/archive' component={ArchiveCompetitiveLandscape} />
            <Route path='/competitive-intel/competitive-landscape/create' component={CreateCompetitiveLandscape} />
            <Route path='/competitive-intel/competitive-landscape/edit/:documentId' component={EditCompetitiveLandscape} />
            <Route path='/competitive-intel/competitive-landscape/view/:documentId' component={ViewCompetitiveLandscape} />

            {/* idea-validation */}
            <Route path='/tools/founders/idea-validation' component={IdeaValidationLandingPage} />
            <Route path='/founders/idea-validation/archive' component={ArchiveIdeaValidation} />
            <Route path='/founders/idea-validation/create' component={CreateIdeaValidation} />
            <Route path='/founders/idea-validation/edit/:ideaValidationId' component={EditIdeaValidation} />
            <Route path='/founders/idea-validation/view/:ideaId' component={ViewIdeaValidation} />

            {/* pitch-deck-analysis */}
            <Route path='/tools/founders/business-plan' component={PitchDeckAnalysis} />
            <Route path='/founders/business-plan/archive' component={ArchivePitchDeckAnalysis} />
            <Route path='/founders/business-plan/create' component={CreatePitchDeckAnalysis} />
            <Route path='/founders/business-plan/edit/:pitchDeckId' component={EditPitchDeckAnalysis} />
            <Route path='/founders/business-plan/view/:pitchDeckId' component={ViewPitchDeckAnalysis} />

            {/* no-code-builder */}
            <Route path='/tools/founders/no-code-builder' component={NoCodeBuilder} />
            <Route path='/founders/no-code-builder/archive' component={ArchiveNoCodeBuilder} />
            <Route path='/founders/no-code-builder/create' component={CreateNoCodeBuilder} />
            <Route path='/founders/no-code-builder/edit/:projectId' component={EditNoCodeBuilder} />

            {/* simulation */}
            <Route path='/simulation/founders/pre-revenue-valuation' component={PreRevenueValuation} />
            <Route path='/simulation/founders/market-sizing' component={MarketSizing} />
            <Route path='/simulation/founders/vc-funding-benchmarks' component={FundingBenchmark} />

            {/* sourcing */}
            <Route path='/sourcing/funding/very-early-stage' component={VeryEarlyStage} />
            <Route path='/sourcing/funding/early-stage' component={EarlyStage} />
            <Route path='/sourcing/funding/growth-stage' component={GrowthStage} />
            <Route path='/sourcing/funding/late-stage' component={LateStage} />
            <Route path='/sourcing/funding/post-ipo-stage' component={PostIpoStage} />
            <Route path='/sourcing/deals/very-early-stage' component={VeryEarlyStageDeals} />
            <Route path='/sourcing/deals/early-stage' component={EarlyStageDeals} />

            {/* user-management */}
            <Route path='/admin/users' component={UsersList} />
          </RequireLoggedInUser>
        </Switch>
      </Router>
      {/* </RealmApolloProvider> */}
    </RealmAppProvider>
  )
}

export default App
